import React from 'react';
import WebpImagePng from './WebpImagePng';

const WebpImageDefault = ({isExternal,  ...props}) => {

  if (isExternal) {
    return <img className={props.className} style={{objectFit: props.objectFit}} alt={props.alt} src={props.fileName}/>
  }

  return (
    <WebpImagePng {...props} />
  )
};

export default WebpImageDefault;
