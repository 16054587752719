import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const WebpImageSvg = ({alt, fileName, className, objectFit="cover", wrapperClassName,  ...props}) => {
    const data = useStaticQuery(graphql`
    query {
      allFile(filter: {ext: {in: [".svg"]} }) {
          nodes {
              name
              publicURL
              relativePath
          }
      }
    }
  `)

  if(!fileName || typeof fileName !== "string") {
    return null
  }

  const image = data.allFile.nodes.find(f => {
    return f.relativePath.toLowerCase() === (fileName.replace(/\s+/g, "").toLowerCase()) ||
      f.name.toLowerCase() === (fileName.replace(/\s+/g, "").toLowerCase()) ||
      f.relativePath == fileName;
  });

  if (!image || !image.publicURL) {
    return <img className={className} style={{objectFit: objectFit}} alt={alt} src={fileName}/>
  }

  return <img className={className} style={{objectFit: objectFit}} alt={alt} src={image.publicURL}/>
};

export default WebpImageSvg;
