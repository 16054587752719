import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const WebpImageWebp = ({alt, fileName, className, objectFit="cover", wrapperClassName,  ...props}) => {
    const data = useStaticQuery(graphql`
    query {
      allFile(filter: {ext: {in: [".webp"]} }) {
          nodes {
              name
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: NONE)
              }
              publicURL
              relativePath
          }
      }
    }
  `)

  if(!fileName || typeof fileName !== "string") {
    return null
  }

  const image = data.allFile.nodes.find(f => {
    return f.relativePath.toLowerCase() === (fileName.replace(/\s+/g, "").toLowerCase()) ||
      f.name.toLowerCase() === (fileName.replace(/\s+/g, "").toLowerCase()) ||
      f.relativePath == fileName;
  });

  if(!image || !image?.childImageSharp || !image?.childImageSharp?.gatsbyImageData) {
    return <img className={className} style={{objectFit: objectFit}} alt={alt} src={image?.publicURL || fileName}/>
  }

  return (
    <div className={wrapperClassName} {...props}>
      {
        image && image.childImageSharp && image?.childImageSharp?.gatsbyImageData && 
        <GatsbyImage
          objectFit={objectFit}
          className={wrapperClassName === className ? "" : className}
          alt={alt}
          image={image.childImageSharp.gatsbyImageData}
          loading={props?.loading}
        />
      }
    </div>
  )
};

export default WebpImageWebp;
