import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const WebpImagePng2 = ({alt, fileName, className, objectFit="cover", wrapperClassName,  ...props}) => {
    const data = useStaticQuery(graphql`
    query {
      allFile(skip: 390, filter: {ext: {in: [".png"]}, relativePath: {nin: ["parallax_pane_1_item_1.png", "parallax_pane_1_item_2.png", "parallax_pane_1_item_3.png","parallax_pane_1_item_4.png", "parallax_pane_1_item_5.png", "parallax_pane_2_item_1.png", "parallax_pane_2_item_2.png", "parallax_pane_2_item_3.png", "parallax_pane_5_item_1.png", "parallax_pane_5_item_2.png", "parallax_pane_5_item_3.png", "Hubble_Other_Guys.de.png", "Hubble_Other_Guys.es.png", "Hubble_Other_Guys.fr.png"]}}) {
          nodes {
              name
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: NONE)
              }
              publicURL
              relativePath
          }
      }
    }
  `)

  if(!fileName || typeof fileName !== "string") {
    return null
  }

  const image = data.allFile.nodes.find(f => {
    return f.relativePath.toLowerCase() === (fileName.replace(/\s+/g, "").toLowerCase()) ||
      f.name.toLowerCase() === (fileName.replace(/\s+/g, "").toLowerCase()) ||
      f.relativePath == fileName;
  });

  if (!image) return null;

  return (
    <div className={wrapperClassName} {...props}>
      {
        image && image.childImageSharp && image?.childImageSharp?.gatsbyImageData && 
        <GatsbyImage
          objectFit={objectFit}
          className={wrapperClassName === className ? "" : className}
          alt={alt}
          image={image.childImageSharp.gatsbyImageData}
          loading={props?.loading}
        />
      }
    </div>
  )

};

export default WebpImagePng2;
