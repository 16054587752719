import React, { useRef, useState, useLayoutEffect} from "react"
import { Link } from "gatsby"

import "./style.scss"

const NewButton = ({
  label,
  to,
  onClick,
  type,
  disabled,
  color,
  className,
  state,
  stickyScroll=false
}) => {
  const isExternalURL =
    to && !!to.includes("https://") && !!to.includes("http://")

  let buttonClass = 'new-button text'

  if (className) buttonClass += ` ${className}`
  if (color) buttonClass += ` col--${color}`

  const stickyRef = useRef()
  const anchorRef = useRef()
  const [sticky, setSticky] = useState(false)
  const banner = typeof document !== "undefined" ? document.querySelector(".discount-banner") : null 
  const nonSticky = typeof document !== "undefined" ? document.querySelector(".extra-padding") : null 

  useLayoutEffect(() => {
    if (!stickyScroll) {
      return
    }

    let isReversed = stickyRef.current?.getBoundingClientRect().top >= anchorRef.current?.getBoundingClientRect().top

    // Leave this console.log in, as for some reason the sticky functionality doesn't work without it
    const handleScroll = () => {
      isReversed = sticky ? isReversed : stickyRef.current?.getBoundingClientRect().top >= anchorRef.current?.getBoundingClientRect().top
      const threshold = (isReversed ? anchorRef.current?.getBoundingClientRect().top : anchorRef.current?.getBoundingClientRect().bottom) - (isReversed ? 0 : stickyRef.current?.offsetHeight) + document.documentElement.scrollTop - 64;
      const buttonOffset = document.getElementById('button')?.offsetTop
      if(buttonOffset > 0 && window.scrollY > threshold) {
        setSticky(true)
      } else {
        setSticky(false)
      };
    };



    window.addEventListener('scroll', handleScroll);
    return () => {
      setSticky(false)
      window.removeEventListener('scroll', handleScroll)
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonClass, stickyScroll]);

  if (type || disabled) {
    return (
      <button
        type={type}
        className={buttonClass}
        disabled={disabled}
        onClick={onClick}
      >
        {typeof label === 'string' ? label : label?.()}
      </button>
    )
  }

  if (to && !isExternalURL)
    return (
      <Link className={buttonClass} to={to} onClick={onClick} state={state}>
        {label}
      </Link>
    )

  return (
    <>
      <button id="button" className={sticky ? `${buttonClass} sticky ${banner ? "include-banner-height" : ""} ${nonSticky ? "non-sticky-banner" : ""}` : buttonClass} ref={stickyRef} onClick={onClick}>
        {label}
      </button>
      <div
        ref={anchorRef}
        style={{
          height: sticky ? `${stickyRef.current?.clientHeight}px` : '0px',
        }}
      />
    </>
  )
}

export default NewButton
