import React from 'react';

import WebpImagePng from './WebpImagePng';
import WebpImagePng2 from './WebpImagePng2';
import WebpImageSvg from './WebpImageSvg';
import WebpImageWebp from './WebpImageWebp';
import WebpImageJpg from './WebpImageJpg';
import WebpImageJpg2 from './WebpImageJpg2';
import WebpImageDefault from './WebpImageDefault';

const WebpImage = (props) => {
  const fileName = props.fileName
  let extension = fileName?.split('.')?.pop()
  if (extension === fileName) extension = null
  const isExternal = fileName?.includes('http')

  return(
    <>
      { extension == 'png' && <WebpImagePng {...props} /> }
      { extension == 'png' && <WebpImagePng2 {...props} /> }
      { extension == 'svg' && <WebpImageSvg {...props} /> }
      { extension == 'webp' && <WebpImageWebp {...props} /> }
      { extension == 'jpg' && <WebpImageJpg {...props} /> }
      { extension == 'jpg' && <WebpImageJpg2 {...props} /> }
      { (isExternal || !extension) && <WebpImageDefault {...props} isExternal={isExternal} /> }
    </>
  )
};

export default WebpImage;
